<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="16">
            <div><img src="/image/logo.jpg" height="60px" alt="logo"/></div>
          </el-col>
          <el-col :span="8">
            <el-menu mode="horizontal" @select="handleMenuSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">产品展示</el-menu-item>
              <el-menu-item index="3">公司介绍</el-menu-item>
              <el-menu-item index="4">联系我们</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <el-breadcrumb separator="">
          <el-breadcrumb-item>您现在的位置：</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>/</el-breadcrumb-item>
          <el-breadcrumb-item>{{articleTitle}}</el-breadcrumb-item>
        </el-breadcrumb>

        <el-row class="article">
          <el-col :span="15">
            <h1>{{articleTitle}}</h1>
            <div class="article-content" v-html="articleContent"></div>
          </el-col>

          <el-col :span="5" :offset="2">
            <h1>相关推荐</h1>
            <div v-for="(item, index) in articleList" :key="index">
              <div class="recommend"><a href="javascript:void(0)" @click="changeArticle(index)">{{item.title}}</a></div>
              <el-divider></el-divider>
            </div>
          </el-col>
        </el-row>
      </el-main>

      <el-footer class="footer">
        <el-row class="nav">
          <el-col :span="1" :offset="7"><router-link to="/">首页</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/product">产品展示</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/introduction">公司介绍</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/contact">联系我们</router-link></el-col>
        </el-row>
        <div class="copyright">Copyright © 广东明业医疗科技有限公司 地址：广东省东莞市莞城街道莞龙路莞城段46号306室</div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articleList: [{
        title: '医疗垃圾桶的五大分类？',
        content: '医疗垃圾桶五大类有：感染性废物、病理性废物、损伤性废物、药物性废物、化学性废物。接下来就让小编给详细大家讲解讲解吧。<br>' +
            '<br>' +
            '感染性废物:<br>' +
            '<br>' +
            '携带病原微生物，具有引发感染性疾病传播危险的医疗废物。<br>' +
            '<br>' +
            '病理性废物：<br>' +
            '<br>' +
            '指代在诊疗过程中产生的人体废弃物和医学实验动物尸体等。手术及其他诊疗过程中产生的废弃的人体组织、器官等。医学实验动物的组织、尸体。病理切片后废弃的人体组织、病理蜡块等。<br>' +
            '<br>' +
            '损伤性废物：<br>' +
            '<br>' +
            '指代可以刺伤或者割伤人体的医用锐器，各类医用锐器，包括：解剖刀、手术刀、备皮刀、手术锯等。载玻片、玻璃试管、玻璃安瓿等。<br>' +
            '<br>' +
            '药物性废物：<br>' +
            '<br>' +
            '指的是过期、淘汰、变质或被污染的废弃药品。废弃的一般的药品，如：抗生素等。<br>' +
            '化学性废物：<br>' +
            '<br>' +
            '指具有毒性，腐蚀性，易燃易爆性的废弃化学物品，比如有分袋分类进行收集，派专人回收处理。医学影像室、实验室废弃的化学试剂。<br>'
      },
        {
          title: '医疗利器盒的优点有哪些？',
          content: '医疗利器盒采用全新聚丙烯塑料，不含PVC，具有方便、无毒、耐穿刺、不渗漏、易于高温焚烧、封闭后无法在不破坏的情况下打开。所以大部分都是一次性的。<br>' +
              '<br>' +
              '医疗利器盒的用途：<br>' +
              '<br>' +
              '医疗利器盒是用于盛装注射器，输液器等一次性使用物品的针头；各类刀片，头皮针，缝合针，安瓿，小玻璃等锐器，收集带血的整副注射器，输血器，血袋等所有接触血液的医用器材；其它规定放入利器盒<br>' +
              '<br>' +
              '的医疗危险感染物品。所以这里面可是不能随便放废品的地方哦！<br>' +
              '<br>' +
              '如果有些需要反复使用的还需要进行消毒，如下。<br>' +
              '<br>' +
              '利器盒放置时间为24小时，48小时有专门人处理。需要将利器盒要进行严格消毒才能反复使用，其实这些基本上都是一次性的，如果有能反复使用的，都会经过好几道程序严格消毒。<br>'
        },
        {
          title: '医疗利器盒怎样使用？',
          content: '利器盒是盛装锐利器具的专用，保护医务人员、防止医疗防范盛装有医疗废物的利器盒。规范使用利器盒，不单单是职业防护的要求，还涉及到了法律责任。关于重复使用利器盒的的法律责任是哪一条，实务中探讨诸多，甚至争议甚大。此时，再将行为细化，重复使用利器盒，是如何重复使用。<br>' +
              '<br>' +
              '1、注意使用利器盒，有无封口。盛装的锐利器具达到利器盒的3/4时，应当使用有效的封口方式，使包装物或者容器的封口紧实、严密。<br>' +
              '<br>' +
              '2.注意利器盒是否符合标准的。标准要求，利器盒一旦被封口，则无法在不破坏的情况下被再次打开，如果封口后可以打开，说明此利器盒并非是专用容器。<br>' +
              '<br>' +
              '3.注意取出锐利器具的去向。锐利器械应当利器盒内盛装，如果取出的直接混装在专用包装袋内、其他非利器盒内，按未将医疗废物按类别分置于专用容器认定。<br>'
        },
        {
          title: '医疗包装袋怎么样？',
          content: '医疗包装袋大多用在医疗器械上，并且大部分都是一次性的使用。首先我们要考虑的就是医疗包装袋的材质，一般都是采用聚乙烯制成，聚乙烯在常温下是耐强酸强碱，在低于80摄氏度的情况下，它的稳定性也是相当好的。一般聚乙烯还可回收再利用的，这有利于缓解我国资源匮乏的压力。<br>' +
              '<br>' +
              '医疗包装袋厚度怎样，一般情况下，医疗设备都需要转运、运输。医疗包装袋的质量就是相当关键的一个指标了，一般厚度在30微米左右。颜色是可以根据自己的需求和爱好来选择的。<br>'
        },
        {
          title: '利器盒如何放置？',
          content: '锋利的工具箱应尽可能放置在靠近工作场所的醒目位置，有一定的空间，光线明亮，无视觉遮挡，这样从完成护理操作到将针头放入锋利的工具箱之间的时间间隔越短，就越能有效避免锋利的工具伤害。锐器盒不应直接放在地面或治疗车的下层。因此，应制作一个架子或一张桌子来放置锐器盒。虽然站立时没有求达到理想的丢弃高度，但也应使操作员足够安全地丢弃锐器，确保有一定的空间、明亮的光线和道路上没有障碍物。如果磨具盒直接放在地面上，则不符合要求。弯腰操作不仅难以准确投入，而且容易露出针头，造成他人磨刀器损坏。<br>' +
              '<br>' +
              '安装利器盒：将箱体与箱盖对接，向下压，整体安装。<br>' +
              '<br>' +
              '左右旋转顶盖上的红色旋转盘以打开或关闭利器盒，逆时针旋转打开，顺时针旋转关闭。<br>' +
              '<br>' +
              '收集注射器针头：将针头伸入水滴孔，夹在注射器乳头与针头的接口处，轻轻向外按压针头筒，注射器针头自动落下.<br>' +
              '<br>' +
              '输液器尖锐部分收集：握住输液器软管，将尖锐部分伸入顶盖大开口内，用剪刀剪断，尖锐部分会掉进去<br>' +
              '<br>' +
              '锋利的工具，如刀片或玻璃，以及血液污染的物品，如用于输血的注射器和输液器，可直接放入顶部的大开口中。<br>'
        },
        {
          title: '利器盒可以做什么用？',
          content: '使用利器盒的目的是避免感染，杜绝非安全注射。利器盒主要有以下功能：<br>' +
              '<br>' +
              '1、收集注射器、输液器等一次性使用物品的针头。<br>' +
              '<br>' +
              '2、收集医用小玻璃制品、各类刀片、头皮针、缝合针等锐器。<br>' +
              '<br>' +
              '3、收集带血的整副注射器、输血器等接触血液的医用器材。<br>' +
              '<br>' +
              '4、其他规定必须放入利器盒的医疗锐器等锐利危险品。<br>' +
              '<br>' +
              '利器盒为一次性使用物品，放入利器盒的医疗废物不得取出。规定24小时内必须由医疗废物处置单位回收，在48小时内彻底安焚化。<br>'
        }
      ],
      articleTitle: '',
      articleContent: ''
    }
  },
  mounted() {
    const articleIndex = this.$route.params.id;
    this.articleTitle = this.articleList[articleIndex].title;
    this.articleContent = this.articleList[articleIndex].content;
  },
  methods: {
    handleMenuSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 1) {
        this.$router.push('/');
      } else if (key == 2) {
        this.$router.push('/product');
      } else if (key == 3) {
        this.$router.push('/introduction');
      } else if (key == 4) {
        this.$router.push('/contact');
      }
    },
    changeArticle(index) {
      this.articleTitle = this.articleList[index].title;
      this.articleContent = this.articleList[index].content;
    }
  }
}
</script>

<style>
  .article {
    margin-top: 20px;
  }

  .article-content {
    font-family: monospace;
    font-size: 18px;
    line-height: 30px;
  }

  .recommend {
    height: 25px;
    line-height: 25px;
  }

  a{
    text-decoration: none;
    color: #000;
  }

  .router-link-active {
    text-decoration: none;
    color: #000;
  }

  .footer {
    text-align: center;
    background-color: #f8f8f8;
    height: 120px !important;
  }

  .nav {
    height: 60px;
    line-height: 60px;
  }

  .copyright {
    margin-top: 30px;
    font-size: 12px;
    text-align: center;
  }
</style>