<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="15" :offset="1">
            <div><img src="/image/logo.jpg" height="60px" alt="logo"/></div>
          </el-col>
          <el-col :span="8">
            <el-menu :default-active="activeIndex" active-text-color="#409EFF" mode="horizontal" @select="handleMenuSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">产品展示</el-menu-item>
              <el-menu-item index="3">公司介绍</el-menu-item>
              <el-menu-item index="4">联系我们</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <el-row>
          <el-col :span="24"><h2>联系我们</h2></el-col>
        </el-row>
        <el-row class="contact">
          <el-col :span="10">
            <div class="contact-info">
              <div>公司地址: 广东省东莞市莞城街道莞龙路莞城段46号306室</div>
              <div>联系电话: 18122933497</div>
            </div>
          </el-col>
          <el-col :span="12">
            <baidu-map class="bm-view" :center="center" :zoom="zoom" @ready="init"></baidu-map>
          </el-col>
        </el-row>
      </el-main>

      <el-footer class="footer">
        <el-row class="nav">
          <el-col :span="1" :offset="7"><router-link to="/">首页</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/product">产品展示</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/introduction">公司介绍</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/contact">联系我们</router-link></el-col>
        </el-row>
        <div class="copyright">Copyright © 广东明业医疗科技有限公司 地址：广东省东莞市莞城街道莞龙路莞城段46号306室</div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '4',
      center: {
        lng: 113.788647,
        lat: 23.056897
      },
      zoom: 25
    }
  },
  methods: {
    handleMenuSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 1) {
        this.$router.push('/');
      } else if (key == 2) {
        this.$router.push('/product');
      } else if (key == 3) {
        this.$router.push('/introduction');
      } else if (key == 4) {
        this.$router.push('/contact');
      }
    },

    init({BMap, map}) {
      this.map = map;
      // 初始化地图,设置中心点坐标
      const point = new BMap.Point(113.786474,23.059076);
      map.centerAndZoom(point, 25);
      // 创建定位标记
      const marker = new BMap.Marker(point);
      map.addOverlay(marker)
      const opts = {
        width: 300,
        height: 65,
        title: '广东明业医疗科技有限公司'
      };
      const infoWindow = new BMap.InfoWindow('地址：广东省东莞市莞城街道莞龙路莞城段46号306室', opts);
      map.openInfoWindow(infoWindow, point);
      // 添加鼠标滚动缩放
      map.enableScrollWheelZoom();
    },
  }
}
</script>

<style>
.container {
  margin: 0 auto;
  max-width: 1440px;
}

.el-menu {
  border: 0 !important;
}

.contact {
  background: url("@/assets/contact_bg.jpg") no-repeat;
  height: 514px;
  width: 100%;
}

.contact-info {
  position: relative;
  top: 180px;
  left: 100px;
  color: #ffffff;
  text-align: left;
  line-height: 35px;
}

.bm-view {
  width: 100%;
  height: 350px;
  margin-top:40px;
  margin-left: 40px;
}

.copyright {
  font-size: 12px;
  text-align: center;
}

.footer {
  text-align: center;
  background-color: #f8f8f8;
  height: 120px !important;
}

.nav {
  height: 60px;
  line-height: 60px;
}

.copyright {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}
</style>
