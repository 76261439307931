<template>
  <div id="app" class="app">
    <router-view></router-view>
    <el-backtop>
      <i class="el-icon-arrow-up"></i>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a{
  text-decoration: none;
}

a:hover {
  color: #409EFF !important;
}

.router-link-active {
  text-decoration: none;
}

.container {
  margin: 0 auto;
  width: 1440px;
}
</style>
