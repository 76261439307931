import VueRouter from "vue-router"
import Index from '@/components/Index'
import Product from '@/components/Product'
import Introduction from '@/components/Introduction'
import Contact from '@/components/Contact'
import Article from '@/components/Article'
import ProductDetail from '@/components/ProductDetail'

//创建router实例
export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/product',
            name: 'Product',
            component: Product
        },
        {
            path: '/introduction',
            name: 'Introduction',
            component: Introduction
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/article/:id',
            name: 'Article',
            component: Article
        },
        {
            path: '/productDetail/:id',
            name: 'ProductDetail',
            component: ProductDetail
        }
    ]
})