import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import BaiduMap from 'vue-baidu-map'
import VueRouter from 'vue-router'
import router from '@/router'

Vue.use(ElementUI);
Vue.use(BaiduMap, {
    ak: 'SKdxypOAHOVwltA7fUcABV8NGWsW4BRB'
})
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
