<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="15" :offset="1">
            <div><img src="/image/logo.jpg" height="60px" alt="logo"/></div>
          </el-col>
          <el-col :span="8">
            <el-menu :default-active="activeIndex" active-text-color="#409EFF" mode="horizontal" @select="handleMenuSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">产品展示</el-menu-item>
              <el-menu-item index="3">公司介绍</el-menu-item>
              <el-menu-item index="4">联系我们</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <el-row>
          <el-col :span="24"><h2>公司介绍</h2></el-col>
        </el-row>
        <el-row class="introduction">
          <el-col :span="10">
            <div class="introduction-content">
              <div>
                广东明业医疗科技有限公司专营医疗废物信息化管理平台系统。产品软、硬件均为自主研发。目前业务已覆 盖了全国十余个省市地区。主营产品有：医疗废物智能转运车、全自动化智能机器人 （隔离点）、智能台秤、手持信息采集（PDA）等。涵盖的业务板块有：1 、医疗废物信息化管理系统；2、医院场景共享充电宝；3、医院场景无人售货机（主 营1、2类医疗耗材）；4、软件定制开发等。
              </div>
            </div>
          </el-col>
          <el-col :span="12" :offset="2">
            <div class="introduction-image">
              <img src="/image/introduction.jpg"/>
            </div>
          </el-col>
        </el-row>
      </el-main>

      <el-footer class="footer">
        <el-row class="nav">
          <el-col :span="1" :offset="7"><router-link to="/">首页</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/product">产品展示</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/introduction">公司介绍</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/contact">联系我们</router-link></el-col>
        </el-row>
        <div class="copyright">Copyright © 广东明业医疗科技有限公司 地址：广东省东莞市莞城街道莞龙路莞城段46号306室</div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '3'
    }
  },
  methods: {
    handleMenuSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 1) {
        this.$router.push('/');
      } else if (key == 2) {
        this.$router.push('/product');
      } else if (key == 3) {
        this.$router.push('/introduction');
      } else if (key == 4) {
        this.$router.push('/contact');
      }
    },
  }
}
</script>

<style>
.container {
  margin: 0 auto;
  max-width: 1440px;
}

.el-menu {
  border: 0 !important;
}

.introduction {
  background-color: #f8f8f8;
  height: 400px;
}

.introduction-content {
  padding: 20px 20px 20px 80px;
  line-height: 35px;
  text-align: left;
}

.introduction-image {
  height: 100%;
}

.introduction-image img {
  height: 400px;
}


.footer {
  text-align: center;
  background-color: #f8f8f8;
  height: 120px !important;
}

.nav {
  height: 60px;
  line-height: 60px;
}

.copyright {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}
</style>
