<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="15" :offset="1">
            <div><img src="/image/logo.jpg" height="60px" alt="logo"/></div>
          </el-col>
          <el-col :span="8">
            <el-menu :default-active="activeIndex" active-text-color="#409EFF" mode="horizontal" @select="handleMenuSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">产品展示</el-menu-item>
              <el-menu-item index="3">公司介绍</el-menu-item>
              <el-menu-item index="4">联系我们</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-carousel height="532px">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="item" width="100%" alt="">
          </el-carousel-item>
        </el-carousel>
        <el-row>
          <el-col :span="24"><h2>产品展示</h2></el-col>
        </el-row>
        <el-row>
          <el-col :span="6" v-for="(item, index) in productList" :key="item.id">
            <div style="margin: 10px 5px">
              <el-card class="product" style="position:relative;" :body-style="{ padding: '0px'}">
                <div class="mask-detail">
                  <router-link :to="'/productDetail/'+index"><el-button type="primary">查看详情</el-button></router-link>
                </div>
                <a class="product-image"><img :src="item.imageUrl" width="100%" alt=""></a>
                <div class="product-title">
                  <span>{{ item.title }}</span>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
        <el-row style="text-align: center">
          <router-link to="/product"><el-button plain style="margin:30px;width:250px;font-size:20px">查看更多</el-button></router-link>
        </el-row>

        <el-row class="introduction">
          <el-col :span="8">
            <div class="introduction-content">
              <h2>公司介绍</h2>
              <div>广东明业医疗科技有限公司专营医疗废物信息化管理平台系统。产品软、硬件均为自主研发。目前业务已覆 盖了全国十余个省市地区。主营产品有：医疗废物智能转运车、全自动化智能机器人
                （隔离点）、智能台秤、手持信息采集...
              </div>
              <el-button type="primary" style="margin-top:10px;width:180px"><router-link to="/introduction" style="color: white">查看更多</router-link></el-button>
            </div>
          </el-col>
          <el-col :span="14" :offset="2">
            <div class="introduction-image">
              <img src="/image/introduction.jpg"/>
            </div>
          </el-col>
        </el-row>

        <div class="hot-question">热门问答</div>
        <el-row class="article">
          <el-col :span="6" class="article-title-box">
            <div v-for="(item, index) in articleList" :key="index" :class="articleIndex == index ? 'article-title article-title-hover' : 'article-title'" @mouseover="onArticleHover(index)">{{index+1}} 、 {{item.title}}</div>
          </el-col>
          <el-col :span="18">
            <div class="article-content-box">
              <div>{{articleTitle}}</div>
              <div v-html="articleContent"></div>
              <div class="article-view"><router-link style="color:#409EFF" :to="'/article/'+articleIndex">查看全部</router-link></div>
            </div>
          </el-col>
        </el-row>

        <el-row class="consult">
          <el-col :span="8" :offset="15">
            <el-card class="box-card">
              <el-row><h2>免费预约获取产品报价</h2></el-row>
              <el-form ref="form" label-width="0px">
                <el-form-item class="consult-form-item">
                  <el-input style="height:90px" placeholder="请输入您的称呼"></el-input>
                </el-form-item>
                <el-form-item class="consult-form-item">
                  <el-input style="height:90px" placeholder="请输入您的联系电话"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" class="btn-submit" @click="openContactTip">立即预约</el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <img src="/image/advantage.jpg"/>
        </el-row>

        <el-row class="contact">
          <el-col :span="10">
            <div class="contact-info">
              <h2>联系我们</h2>
              <div>公司地址: 广东省东莞市莞城街道莞龙路莞城段46号306室</div>
              <div>联系电话: 18122933497</div>
            </div>
          </el-col>
          <el-col :span="12">
            <baidu-map class="bm-view" :center="center" :zoom="zoom" @ready="init"></baidu-map>
          </el-col>
        </el-row>
      </el-main>

      <el-footer class="footer">
        <el-row class="nav">
          <el-col :span="1" :offset="7"><router-link to="/">首页</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/product">产品展示</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/introduction">公司介绍</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/contact">联系我们</router-link></el-col>
        </el-row>
        <div class="copyright">Copyright © 广东明业医疗科技有限公司 地址：广东省东莞市莞城街道莞龙路莞城段46号306室</div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
      center: {
        lng: 113.788647,
        lat: 23.056897
      },
      zoom: 25,
      bannerList: ['../../image/banner1.jpg', '../../image/banner2.jpg'],
      productList: [
        {
          id: 1,
          title: '医疗废物信息收集终端PDA 医废收集PDA',
          imageUrl: '../../image/product/pda.jpg'
        },
        {
          id: 2,
          title: '医废专用蓝牙秤',
          imageUrl: '../../image/product/zhinengcheng.jpg'
        },
        {
          id: 3,
          title: '智能医废收集车 电动助力收集车',
          imageUrl: '../../image/product/shoujiche.jpg'
        },
        {
          id: 4,
          title: '医废回收蓝牙打印机',
          imageUrl: '../../image/product/printer.jpg'
        },
        {
          id: 5,
          title: '医废专用溯源标签打印纸',
          imageUrl: '../../image/product/printer_pager.jpg'
        },
        {
          id: 6,
          title: '智能无人转运机器人 全自动机器人',
          imageUrl: '../../image/product/robot.jpg'
        },
        {
          id: 7,
          title: '医废智能一体秤',
          imageUrl: '../../image/product/yiticheng.jpg'
        },
        {
          id: 8,
          title: '医废专用垃圾袋',
          imageUrl: '../../image/product/lajidai.jpg'
        },
      ],
      articleList: [{
          title: '医疗垃圾桶的五大分类？',
          content: '医疗垃圾桶五大类有：感染性废物、病理性废物、损伤性废物、药物性废物、化学性废物。接下来就让小编给详细大家讲解讲解吧。<br>' +
              '<br>' +
              '感染性废物:<br>' +
              '<br>' +
              '携带病原微生物，具有引发感染性疾病传播危险的医疗废物。<br>' +
              '<br>' +
              '病理性废物：<br>' +
              '<br>' +
              '指代在诊疗过程中产生的人体废弃物和医学实验动物尸体等。手术及其他诊疗过程中产生的废弃的人体组织、器官等。医学实验动物的组织、尸体。病理切片后废弃的人体组织、病理蜡块等。<br>' +
              '<br>' +
              '损伤性废物：<br>' +
              '<br>' +
              '指代可以刺伤或者割伤人体的医用锐器，各类医用锐器，包括：解剖刀、手术刀、备皮刀、手术锯等。载玻片、玻璃试管、玻璃安瓿等。<br>' +
              '<br>' +
              '药物性废物：<br>' +
              '<br>' +
              '指的是过期、淘汰、变质或被污染的废弃药品。废弃的一般的药品，如：抗生素等。<br>' +
              '化学性废物：<br>' +
              '<br>' +
              '指具有毒性，腐蚀性，易燃易爆性的废弃化学物品，比如有分袋分类进行收集，派专人回收处理。医学影像室、实验室废弃的化学试剂。<br>'
        },
        {
          title: '医疗利器盒的优点有哪些？',
          content: '医疗利器盒采用全新聚丙烯塑料，不含PVC，具有方便、无毒、耐穿刺、不渗漏、易于高温焚烧、封闭后无法在不破坏的情况下打开。所以大部分都是一次性的。<br>' +
              '<br>' +
              '医疗利器盒的用途：<br>' +
              '<br>' +
              '医疗利器盒是用于盛装注射器，输液器等一次性使用物品的针头；各类刀片，头皮针，缝合针，安瓿，小玻璃等锐器，收集带血的整副注射器，输血器，血袋等所有接触血液的医用器材；其它规定放入利器盒<br>' +
              '<br>' +
              '的医疗危险感染物品。所以这里面可是不能随便放废品的地方哦！<br>' +
              '<br>' +
              '如果有些需要反复使用的还需要进行消毒，如下。<br>' +
              '<br>' +
              '利器盒放置时间为24小时，48小时有专门人处理。需要将利器盒要进行严格消毒才能反复使用，其实这些基本上都是一次性的，如果有能反复使用的，都会经过好几道程序严格消毒。<br>'
        },
        {
          title: '医疗利器盒怎样使用？',
          content: '利器盒是盛装锐利器具的专用，保护医务人员、防止医疗防范盛装有医疗废物的利器盒。规范使用利器盒，不单单是职业防护的要求，还涉及到了法律责任。关于重复使用利器盒的的法律责任是哪一条，实务中探讨诸多，甚至争议甚大。此时，再将行为细化，重复使用利器盒，是如何重复使用。<br>' +
              '<br>' +
              '1、注意使用利器盒，有无封口。盛装的锐利器具达到利器盒的3/4时，应当使用有效的封口方式，使包装物或者容器的封口紧实、严密。<br>' +
              '<br>' +
              '2.注意利器盒是否符合标准的。标准要求，利器盒一旦被封口，则无法在不破坏的情况下被再次打开，如果封口后可以打开，说明此利器盒并非是专用容器。<br>' +
              '<br>' +
              '3.注意取出锐利器具的去向。锐利器械应当利器盒内盛装，如果取出的直接混装在专用包装袋内、其他非利器盒内，按未将医疗废物按类别分置于专用容器认定。<br>'
        },
        {
          title: '医疗包装袋怎么样？',
          content: '医疗包装袋大多用在医疗器械上，并且大部分都是一次性的使用。首先我们要考虑的就是医疗包装袋的材质，一般都是采用聚乙烯制成，聚乙烯在常温下是耐强酸强碱，在低于80摄氏度的情况下，它的稳定性也是相当好的。一般聚乙烯还可回收再利用的，这有利于缓解我国资源匮乏的压力。<br>' +
              '<br>' +
              '医疗包装袋厚度怎样，一般情况下，医疗设备都需要转运、运输。医疗包装袋的质量就是相当关键的一个指标了，一般厚度在30微米左右。颜色是可以根据自己的需求和爱好来选择的。<br>'
        },
        {
          title: '利器盒如何放置？',
          content: '锋利的工具箱应尽可能放置在靠近工作场所的醒目位置，有一定的空间，光线明亮，无视觉遮挡，这样从完成护理操作到将针头放入锋利的工具箱之间的时间间隔越短，就越能有效避免锋利的工具伤害。锐器盒不应直接放在地面或治疗车的下层。因此，应制作一个架子或一张桌子来放置锐器盒。虽然站立时没有求达到理想的丢弃高度，但也应使操作员足够安全地丢弃锐器，确保有一定的空间、明亮的光线和道路上没有障碍物。如果磨具盒直接放在地面上，则不符合要求。弯腰操作不仅难以准确投入，而且容易露出针头，造成他人磨刀器损坏。<br>' +
              '<br>' +
              '安装利器盒：将箱体与箱盖对接，向下压，整体安装。<br>' +
              '<br>' +
              '左右旋转顶盖上的红色旋转盘以打开或关闭利器盒，逆时针旋转打开，顺时针旋转关闭。<br>' +
              '<br>' +
              '收集注射器针头：将针头伸入水滴孔，夹在注射器乳头与针头的接口处，轻轻向外按压针头筒，注射器针头自动落下.<br>' +
              '<br>' +
              '输液器尖锐部分收集：握住输液器软管，将尖锐部分伸入顶盖大开口内，用剪刀剪断，尖锐部分会掉进去<br>' +
              '<br>' +
              '锋利的工具，如刀片或玻璃，以及血液污染的物品，如用于输血的注射器和输液器，可直接放入顶部的大开口中。<br>'
        },
        {
          title: '利器盒可以做什么用？',
          content: '使用利器盒的目的是避免感染，杜绝非安全注射。利器盒主要有以下功能：<br>' +
              '<br>' +
              '1、收集注射器、输液器等一次性使用物品的针头。<br>' +
              '<br>' +
              '2、收集医用小玻璃制品、各类刀片、头皮针、缝合针等锐器。<br>' +
              '<br>' +
              '3、收集带血的整副注射器、输血器等接触血液的医用器材。<br>' +
              '<br>' +
              '4、其他规定必须放入利器盒的医疗锐器等锐利危险品。<br>' +
              '<br>' +
              '利器盒为一次性使用物品，放入利器盒的医疗废物不得取出。规定24小时内必须由医疗废物处置单位回收，在48小时内彻底安焚化。<br>'
        }
      ],
      articleIndex: 0,
      articleTitle: '',
      articleContent: ''
    };
  },
  mounted() {
    this.articleContent = this.articleList[0].content;
  },
  methods: {
    handleMenuSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 2) {
        this.$router.push('/product');
      } else if (key == 3) {
        this.$router.push('/introduction');
      } else if (key == 4) {
        this.$router.push('/contact');
      }
    },
    init({BMap, map}) {
      this.map = map;
      // 初始化地图,设置中心点坐标
      const point = new BMap.Point(113.786474,23.059076);
      map.centerAndZoom(point, 25);
      // 创建定位标记
      const marker = new BMap.Marker(point);
      map.addOverlay(marker)
      const opts = {
        width: 300,
        height: 65,
        title: '广东明业医疗科技有限公司'
      };
      const infoWindow = new BMap.InfoWindow('地址：广东省东莞市莞城街道莞龙路莞城段46号306室', opts);
      map.openInfoWindow(infoWindow, point);
      // 添加鼠标滚动缩放
      map.enableScrollWheelZoom();
    },

    onArticleHover(index){
      this.articleIndex = index;
      this.articleContent = this.articleList[index].content;
    },

    openContactTip() {
      this.$alert('抱歉，该功能暂未开放', '提示', {
        confirmButtonText: '确定'
      });
    }
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
  width: 1440px;
}

.el-menu {
  border: 0 !important;
}

.product-title {
  padding: 20px;
  border: solid 1px #f2f2f2;
}

.product-image {
  width: 100%;
  height: 280px;
  display: block;
}

.mask-detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
  line-height: 280px;
  opacity: 0;
  background-color: rgba(233, 231, 231, 0.5);
}

.product {
  cursor: pointer;
  text-align: center;
}

.product-image img {
  transition: all 0.5s ease-in-out 0s;
}

.product:hover img {
  transform: scale(1.15);
}

.product:hover .mask-detail {
  z-index: 999;
  opacity: 1;
}

.consult {
  margin-top: 20px;
  background: url("@/assets/consult_bg.jpg") no-repeat;
  height: 514px;
  padding: 20px;
}

.box-card {
  margin-top: 35px;
}

.consult-form-item :deep(input) {
  height: 60px;
}

.btn-submit {
  display: block;
  width: 100%;
  height: 60px;
  font-size: 20px;
}

.introduction {
  background-color: #f8f8f8;
  height: 400px;
}

.introduction-content {
  padding: 20px 20px 20px 80px;
  line-height: 35px;
  text-align: left;
}

.introduction-image {
  height: 100%;
}

.introduction-image img {
  height: 400px;
}

.contact {
  background: url("@/assets/contact_bg.jpg") no-repeat;
  height: 514px;
  width: 100%;
}

.contact-info {
  position: relative;
  top: 140px;
  left: 110px;
  color: #ffffff;
  text-align: left;
  line-height: 35px;
}

.bm-view {
  width: 100%;
  height: 350px;
  margin-top:40px;
  margin-left: 40px;
}

.hot-question {
  font-weight: bold;
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
}

.article {
  margin: 10px 0;
  border: solid 1px #f8f8f8;
  border-radius: 4px
}

.article-title-box {
  text-align: left;
  background-color: #f8f8f8;
  padding: 10px 0 10px 10px;
}

.article-title {
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
}

.article-title-hover {
  background-color: #ffffff;
}

.article-content-box {
  text-align: left;
  padding: 20px;
  overflow: hidden;
  height: 280px;
  position: relative;
  font-size: 17px;
}

.article-view {
  height: 110px;
  line-height: 170px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 22px;
  color: #0068b8;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 66%);
}

.footer {
  text-align: center;
  background-color: #f8f8f8;
  height: 120px !important;
}

.nav {
  height: 60px;
  line-height: 60px;
}

.copyright {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}
</style>