<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="16">
            <div><img src="/image/logo.jpg" height="60px" alt="logo"/></div>
          </el-col>
          <el-col :span="8">
            <el-menu mode="horizontal" @select="handleMenuSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">产品展示</el-menu-item>
              <el-menu-item index="3">公司介绍</el-menu-item>
              <el-menu-item index="4">联系我们</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <el-breadcrumb separator="">
          <el-breadcrumb-item>您现在的位置：</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>/</el-breadcrumb-item>
          <el-breadcrumb-item>产品展示</el-breadcrumb-item>
          <el-breadcrumb-item>/</el-breadcrumb-item>
          <el-breadcrumb-item>{{product.title}}</el-breadcrumb-item>
        </el-breadcrumb>

        <el-row class="product-box">
          <el-col :span="9">
            <div class="product-image"><img :src="product.imageUrl" width="90%" height="90%"/></div>
          </el-col>
          <el-col :span="15">
            <div class="description">{{product.description}}</div>
            <div class="remark">{{product.remark}}</div>
            <div class="price">价格面议</div>
            <div class="tag-box">
                <el-tag v-for="tag in product.tagList" :key="tag" effect="dark" class="tag">
                  {{ tag }}
                </el-tag>
            </div>
          </el-col>
        </el-row>

        <el-tabs type="border-card" class="detail">
          <el-tab-pane>
            <span slot="label">产品详情</span>
            <div>
              <img :src="product.detailImgUrl" width="100%"/>
            </div>
          </el-tab-pane>

        </el-tabs>
      </el-main>

      <el-footer class="footer">
        <el-row class="nav">
          <el-col :span="1" :offset="7"><router-link to="/">首页</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/product">产品展示</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/introduction">公司介绍</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/contact">联系我们</router-link></el-col>
        </el-row>
        <div class="copyright">Copyright © 广东明业医疗科技有限公司 地址：广东省东莞市莞城街道莞龙路莞城段46号306室</div>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
      product: '',
      productList: [
        {
          id: 1,
          title: '医疗废物信息收集终端PDA 医废收集PDA',
          imageUrl: '../../image/product/pda.jpg',
          description: '医废收集pda 手持终端机 医疗废物信息终端收集、分类、打印、出入库等',
          remark: '用于接收上述设备记录的回收人员、科室地点、交接人员、垃圾类别、垃圾重量等信息，并整理成一条完整的回收记录实时上传至监管平台，实现让每一件医疗垃圾都处于可控、可管的实时监管状态。',
          detailImgUrl: '../../image/product/pda_detail.jpg',
          tagList: ['专注专业']
        },
        {
          id: 2,
          title: '医废专用蓝牙秤',
          imageUrl: '../../image/product/zhinengcheng.jpg',
          description: '医废专用蓝牙秤',
          remark: '',
          detailImgUrl: '../../image/product/zhinengcheng_detail.jpg',
          tagList: ['专注专业']
        },
        {
          id: 3,
          title: '智能医废收集车 电动助力收集车',
          imageUrl: '../../image/product/shoujiche.jpg',
          description: '医废电动助力收集车、304不锈钢材质、智能称重上传',
          remark: '',
          detailImgUrl: '../../image/product/shoujiche_detail.jpg',
          tagList: ['电动助力医废收集车', '医疗废物溯源系统', '随车监控', '医疗废物信息化系统', '智能医废系统收集车']
        },
        {
          id: 4,
          title: '医废回收蓝牙打印机',
          imageUrl: '../../image/product/printer.jpg',
          description: '医废回收蓝牙打印机',
          remark: '',
          detailImgUrl: '../../image/product/printer_detail.jpg',
          tagList: ['专注专业']
        },
        {
          id: 5,
          title: '医废专用溯源标签打印纸',
          imageUrl: '../../image/product/printer_pager.jpg',
          description: '医疗废物溯源标签打印卷纸、医疗废物不干胶标签连卷、医废标签纸、医废三防热敏打印纸',
          remark: '三防热敏纸，留边便撕；打印流畅',
          detailImgUrl: '../../image/product/printer_paper_detail.jpg',
          tagList: ['三防热敏纸', '医疗废物不干胶打印卷', '医疗废物标签纸', '不干胶连卷打印纸', '黄色医疗废物打印纸']
        },
        {
          id: 6,
          title: '智能无人转运机器人 全自动机器人',
          imageUrl: '../../image/product/robot.jpg',
          description: '智能机器人、医院智能物流配送机器人',
          remark: '',
          detailImgUrl: '../../image/product/robot_detail.jpg',
          tagList: ['专注专业']
        },
        {
          id: 7,
          title: '医废智能一体秤',
          imageUrl: '../../image/product/yiticheng.jpg',
          description: '医废智能一体秤',
          remark: '',
          detailImgUrl: '../../image/product/yiticheng_detail.jpg',
          tagList: ['专注专业']
        },
        {
          id: 8,
          title: '医废专用垃圾袋',
          imageUrl: '../../image/product/lajidai.jpg',
          description: '医废专用垃圾袋',
          remark: '',
          detailImgUrl: '../../image/product/lajidai_detail.jpg',
          tagList: ['专注专业']
        },
      ],
    }
  },
  mounted() {
    const productIndex = this.$route.params.id;
    this.product = this.productList[productIndex];
  },
  methods: {
    handleMenuSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 1) {
        this.$router.push('/');
      } else if (key == 2) {
        this.$router.push('/product');
      } else if (key == 3) {
        this.$router.push('/introduction');
      } else if (key == 4) {
        this.$router.push('/contact');
      }
    },
  }
}
</script>

<style>
.product-box {
  margin-top: 40px;
}

.product-image {
  width: 480px;
  height: 350px;
  border: solid 1px #f0f0f0;
}

.description {
  font-weight: bold;
  font-size: 26px;
}

.remark {
  margin: 20px 0;
}

.price {
  background-color: #f8f8f8;
  height: 80px;
  line-height: 80px;
  padding-left: 10px;
  margin-top: 30px;
  font-size: 26px;
  font-weight: bold;
  color: red;
}

.tag-box {
  margin-top: 30px;
}

.tag {
  margin-right: 10px;
}

.detail {
  margin-top: 30px;
}


.footer {
  text-align: center;
  background-color: #f8f8f8;
  height: 120px !important;
}

.nav {
  height: 60px;
  line-height: 60px;
}

.copyright {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}
</style>