<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="15" :offset="1">
            <div><img src="/image/logo.jpg" height="60px" alt="logo"/></div>
          </el-col>
          <el-col :span="8">
            <el-menu :default-active="activeIndex" active-text-color="#409EFF" mode="horizontal" @select="handleMenuSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">产品展示</el-menu-item>
              <el-menu-item index="3">公司介绍</el-menu-item>
              <el-menu-item index="4">联系我们</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <el-row>
          <el-col :span="24" style="margin-left: 10px"><h2>产品展示</h2></el-col>
        </el-row>
        <el-row>
          <el-col :span="6" v-for="(item, index) in productList" :key="item.id">
            <div style="margin: 10px 5px">
              <router-link :to="'/productDetail/'+index">
                <el-card class="product" style="position:relative;" :body-style="{ padding: '0px'}">
                  <a class="product-image"><img :src="item.imageUrl" width="100%" alt=""></a>
                  <div class="product-title">
                    <span>{{ item.title }}</span>
                  </div>
                </el-card>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </el-main>

      <el-footer class="footer">
        <el-row class="nav">
          <el-col :span="1" :offset="7"><router-link to="/">首页</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/product">产品展示</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/introduction">公司介绍</router-link></el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2"><router-link to="/contact">联系我们</router-link></el-col>
        </el-row>
        <div class="copyright">Copyright © 广东明业医疗科技有限公司 地址：广东省东莞市莞城街道莞龙路莞城段46号306室</div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '2',
      productList: [
        {
          id: 1,
          title: '医疗废物信息收集终端PDA 医废收集PDA',
          imageUrl: '../../image/product/pda.jpg'
        },
        {
          id: 2,
          title: '医废专用蓝牙秤',
          imageUrl: '../../image/product/zhinengcheng.jpg'
        },
        {
          id: 3,
          title: '智能医废收集车 电动助力收集车',
          imageUrl: '../../image/product/shoujiche.jpg'
        },
        {
          id: 4,
          title: '医废回收蓝牙打印机',
          imageUrl: '../../image/product/printer.jpg'
        },
        {
          id: 5,
          title: '医废专用溯源标签打印纸',
          imageUrl: '../../image/product/printer_pager.jpg'
        },
        {
          id: 6,
          title: '智能无人转运机器人 全自动机器人',
          imageUrl: '../../image/product/robot.jpg'
        },
        {
          id: 7,
          title: '医废智能一体秤',
          imageUrl: '../../image/product/yiticheng.jpg'
        },
        {
          id: 8,
          title: '医废智能一体秤',
          imageUrl: '../../image/product/lajidai.jpg'
        },
      ],
    }
  },
  methods: {
    handleMenuSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 1) {
        this.$router.push('/');
      } else if (key == 2) {
        this.$router.push('/product');
      } else if (key == 3) {
        this.$router.push('/introduction');
      } else if (key == 4) {
        this.$router.push('/contact');
      }
    },
  }
}
</script>

<style>
.container {
  margin: 0 auto;
  width: 1440px;
}

.el-menu {
  border: 0 !important;
}

.product {
  cursor: pointer;
  text-align: center;
}

.product-image img {
  transition: all 0.5s ease-in-out 0s;
}

.product:hover img {
  transform: scale(1.15);
}

.product-title {
  padding: 20px;
  border: solid 1px #f2f2f2;
}


.footer {
  text-align: center;
  background-color: #f8f8f8;
  height: 120px !important;
}

.nav {
  height: 60px;
  line-height: 60px;
}

.copyright {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}
</style>